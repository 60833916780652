
var LiteSlider = function( selector, params ) {
	var _ls       = this;
	var _settings = params || {};

	var _switchEvents = [];
	var _mask         = _settings.hasOwnProperty("flags") ? 
						_settings.flags : 
						LiteSlider.Flags.AUTOPLAY | LiteSlider.Flags.REVERT_ON_END;

	var _pushBy         = _settings.pushBy || 0;
	var _shift          = _settings.shift || 1;
	var _slideInterval  = _settings.interval || 5000;
	var _slideDirection = _settings.direction || "horizontal";
	var _items          = document.querySelectorAll(selector);
	var _refItem        = _items[0];
	var _cssProp        = _slideDirection == "vertical" ? "translateY": "translateX";

	if (!_refItem) {
		return;
	}

	var _wrapper;
	var _container = _refItem.parentNode;

	var nodes = selector.split(" ");
	_wrapper = document.querySelector(nodes[0]);

	if (!_container || !_wrapper) {
		return;
	}

	_wrapper.setAttribute("data-liteslider-js", "true");

	var _autoplayInterval,
	    _isStopped         = true,
	    _currentSlide      = 1,
	    _currentDirection  = 1,
	    _slideCount        = _items.length,
	    _transformProp     = getSupportedTransform(),
	    _itemSize,
	    _visibleSlideCount;

	var isAutoplay          = isFlagSet(LiteSlider.Flags.AUTOPLAY);
	var isAutoplayAlternate = isFlagSet(LiteSlider.Flags.AUTOPLAY_ALTERNATE);
	var isRevertOnEnd       = isFlagSet(LiteSlider.Flags.REVERT_ON_END);

	var activeLinkClass = "liteslider-link-active";


	/***********************
	* ANONYMNÍ FUNKCE
	***********************/

	function isFlagSet( flag ) {
		return ((_mask & flag) == flag);
	}

	function getSupportedTransform() {
	  var prefixes = ["ms", "Webkit", "Moz", "O"];
	  var prefixProp;

	  prefixes.forEach(function(prefix) {
		 prefixProp = prefix + "Transform";

		 if (_container.style[prefixProp] !== undefined) {
			return prefixProp;
		 }
	  });

		return "transform";
	}

	function handleAutoplay() {
		var index;

		console.log(_currentDirection);
		if (_currentDirection > 0) {
			index = _currentSlide+_visibleSlideCount;

			if (index <= _slideCount) {
				_ls.Next();
			} else {
				if (isAutoplayAlternate) {
					_currentDirection = -1;
					_ls.Previous();
				} else if (isRevertOnEnd) {
					_ls.Slide(1);
				}
			}
		} else {
			if (isAutoplayAlternate) {
				index = _currentSlide-1;

				if (index > 0) {
					_ls.Previous();
				} else {
					_currentDirection = 1;
					_ls.Next();
				}
			}
		}
	}

	

	function init() {
	  if (_slideDirection == "vertical") {
		 _itemSize = _refItem.getBoundingClientRect().height;
		 _visibleSlideCount = Math.round(_container.offsetHeight/_itemSize);
	  } else {
		 _itemSize = _refItem.getBoundingClientRect().width;
		 _visibleSlideCount = Math.round(_container.offsetWidth/_itemSize);
	  }
	  //console.log(_itemSize);

		if (_shift == "auto") {
		 if (_slideDirection == "vertical") {
			_shift = Math.round(_container.offsetHeight/_itemSize);
		 } else {
			_shift = Math.round(_container.offsetWidth/_itemSize);
		 }
		}

		window.addEventListener("resize", function() {
		 var amount;

			if (_slideDirection == "vertical") {
			_itemSize = _refItem.getBoundingClientRect().height;
			amount = Math.round(_container.offsetHeight/_itemSize);
		 } else {
			_itemSize = _refItem.getBoundingClientRect().width;
			amount = Math.round(_container.offsetWidth/_itemSize);
		 }

			if (_shift == "auto") {
				_shift = amount;
			}

			if (_visibleSlideCount !== amount) {
				_visibleSlideCount = amount;

				if (_shift > amount) {
					_shift = amount;
				}
			}

			_ls.Slide(_currentSlide, true);
		});

	  var startingLen = 0+(_itemSize*_pushBy);
	  _container.style[_transformProp] = _cssProp + "(" + startingLen + "px)";

		/******************************************************
		* OPRAVA ODKAZŮ VE SLIDECH - SPRÁVNÉ PŘEJETÍ NA FOCUS
		*******************************************************/

	  var itemLen = _slideCount;

	  while (itemLen) {
		 --itemLen;
		 _items[itemLen].addEventListener("keyup", function(e) {
			var target = e.target;

			if (target && target.tagName.toLowerCase() === "a" && e.keyCode === 9) {
			   e.preventDefault();
			   var indexLS = ( [].indexOf.call(_items, this) + 1 );

			   if (indexLS && indexLS !== _currentSlide) {
				  _ls.Slide(indexLS);
			   }

			   return false;
			}
		 });
	  }

	  // for (var il = 0; il < _slideCount; il++) {
	  //    var item = _items[il];
	  //    var links = item.getElementsByTagName("a");
	  //    var linkLen = links.length;
		 
	  //    while (linkLen) {
	  //       --linkLen;
	  //       links[linkLen].iiLS = ( [].indexOf.call(_items, item) + 1);
	  //       links[linkLen].addEventListener("focus", function(e) {
	  //          e.preventDefault();
	  //          var indexLS = this.iiLS;

	  //          if (indexLS && indexLS !== _currentSlide) {
	  //             _ls.Slide(indexLS);
	  //          }

	  //          return false;
	  //       });
	  //    }
	  // }
	}


	/******************** ***
	* METODY
	************************/

	_ls.ForceResize = function() {
		var amount;

		if (_slideDirection == "vertical") {
			_itemSize = _refItem.getBoundingClientRect().height;
			amount = Math.round(_container.offsetHeight/_itemSize);
		} else {
			_itemSize = _refItem.getBoundingClientRect().width;
			amount = Math.round(_container.offsetWidth/_itemSize);
		}

		if (_shift == "auto") {
			_shift = amount;
		}

		if (_visibleSlideCount !== amount) {
			_visibleSlideCount = amount;

			if (_shift > amount) {
				_shift = amount;
			}
		}

		_ls.Slide(_currentSlide, true);
		console.log("forced resize");
	}

	_ls.GetCurrentSlide = function() {
	  return _currentSlide;
	}

	_ls.GetItemCount = function() {
		return _slideCount;
	}

	_ls.GetVisible = function() {
		var z,
			arr = [],
			end = _currentSlide + _visibleSlideCount;

		for (z = _currentSlide; z < end; z++) {
			if (_items[z-1]) {
			arr.push(z);
			}
		}

		return arr;
	}

	_ls.RegisterSwitchEvent = function( callable ) {
		if (typeof callable == "function") {
			_switchEvents.push(callable);
		}

		return _ls;
	}

	_ls.GetContainer = function() {
		return _container;
	}

	_ls.CreateArrows = function( appendTo, params ) {
		var parametry = params || {};
		var defaultHref = "javascript:void(0);";

		var arrowElement      = parametry.arrowElement || "a",
			arrowRightContent = parametry.arrowRightContent || null,
			arrowLeftContent  = parametry.arrowLeftContent || null,
			arrowOrder        = parametry.arrowOrder || "auto";

		var arrowRight = document.createElement(arrowElement);
		var arrowLeft = document.createElement(arrowElement);

		arrowRight.setAttribute("tabindex", "0");
		arrowLeft.setAttribute("tabindex", "0");
		arrowRight.id = parametry.arrowRightId || "arrowRight";
		arrowLeft.id = parametry.arrowLeftId || "arrowLeft";

		if (arrowElement == "a") {
			arrowLeft.href = defaultHref;
			arrowRight.href = defaultHref;
		}

		if (arrowRightContent) {
			arrowRight.innerHTML = arrowRightContent;
		}

		if (arrowLeftContent) {
			arrowLeft.innerHTML = arrowLeftContent;
		}

		arrowRight.addEventListener("click", _ls.Next);
		arrowLeft.addEventListener("click", _ls.Previous);

		arrowRight.addEventListener("keyup", function(e) {
			if (e.keyCode === 13) {
			_ls.Next();
			}
		});

		arrowLeft.addEventListener("keyup", function(e) {
			if (e.keyCode === 13) {
			_ls.Previous();
			}
		});

		function handleArrowClasses() {
			var classVar = "liteslider-arrow-disabled";

			if (_currentSlide === 1) {
			arrowLeft.className = classVar;
			} else {
			arrowLeft.className = "";
			}

			if (_currentSlide+_visibleSlideCount-1 === _slideCount) {
			arrowRight.className = classVar;
			} else {
			arrowRight.className = "";
			}
		}

		if (!isRevertOnEnd) {
			handleArrowClasses();
			_ls.RegisterSwitchEvent(handleArrowClasses);
		}

		if (appendTo) {
			if (arrowOrder == "reversed") {
			if (appendTo.children[0]) {
				appendTo.insertBefore(arrowRight, appendTo.children[0])
			} else {
				appendTo.appendChild(arrowRight);
			}

			appendTo.appendChild(arrowLeft);
			} else {
			if (appendTo.children[0]) {
				appendTo.insertBefore(arrowLeft, appendTo.children[0])
			} else {
				appendTo.appendChild(arrowLeft);
			}

			appendTo.appendChild(arrowRight);
			}
		}

		return _ls;
	}

	_ls.Slide = function( index, overrideCurrent ) {
		if (index == _currentSlide && overrideCurrent !== true) {
			return _ls;
		}

		if (isAutoplay) {
			clearInterval(_autoplayInterval);
		}

		var shift = ( index < _currentSlide ? parseInt(index)-(_shift-1) : parseInt(index)+(_shift-1) );

		if (shift > 0 && shift+_visibleSlideCount <= _slideCount+1) {
			_currentSlide = shift;
		} else {
			if (shift <= 0) {
			if (!isRevertOnEnd) { 
				_currentSlide = 1;
			} else {
				_currentSlide = _slideCount-_visibleSlideCount+1;
			}
			} else {
			if (isRevertOnEnd) {
				_currentSlide = 1;
			} else {
				_currentSlide = _slideCount-_visibleSlideCount+1;
			}
			}
		}

		_container.style[_transformProp] = _cssProp + "(-" + ((_itemSize*(_currentSlide-1))-(_itemSize*_pushBy)) + "px)";

		if (isAutoplay && !_isStopped) {
			_ls.Play();
		}

		if (_switchEvents.length > 0) {
			_switchEvents.forEach(function(element) {
			element();
			});
		}

		return _ls;
	}

	_ls.Next = function() {
		_currentDirection = 1;
		return _ls.Slide(_currentSlide+1);
	}

	_ls.Previous = function() {
		_currentDirection = -1;
		return _ls.Slide(_currentSlide-1);
	}

	_ls.CreateLinks = function( appendTo ) {
		var link,
			links = [];

		var visible = _ls.GetVisible();

		for (var l = 0; l < _slideCount; l++) {
			link = document.createElement("a");
			link.innerHTML = l+1;
			link.href = "javascript:void(0);";

			link.addEventListener("click", function(e) {
			var index = links.indexOf(this)+1;

			if (index+_visibleSlideCount-1 >= _slideCount) {
				index = _slideCount-_visibleSlideCount+1;
			}

			_ls.Slide(index);
			});

			link.className = visible.indexOf(l+1) > -1 ? activeLinkClass : "";
			links.push(link);
			appendTo.appendChild(link);
		}

		_ls.RegisterSwitchEvent(function() {
			var visible = _ls.GetVisible();

			links.forEach(function(el, index, array) {
			el.className = visible.indexOf(index+1) > -1 ? activeLinkClass : "";
			});
		});

		return _ls;
	}

	_ls.Play = function() {
		_isStopped = false;
		_autoplayInterval = setInterval(function() {
			handleAutoplay();
		}, _slideInterval);

		return _ls;
	}

	_ls.Stop = function() {
		clearInterval(_autoplayInterval);
		_isStopped = true;

		return _ls;
	}

	// _ls.SetInterval = function( interval ) {
	//    if (isAutoplay) {
	//       clearInterval(_autoplayInterval);
	//    }

	//    _slideInterval = interval;

	//    if (isAutoplay && !_isStopped) {
	//       _ls.Play();
	//    }
	// }


	/**********************
	* INICIALIZACE
	**********************/

	if (isAutoplay) {
		_ls.Play();
	}

	document.addEventListener("readystatechange", function () {
		if (document.readyState == "interactive") {
			init();

			if (_settings.afterInit) {
				_settings.afterInit(_ls);
			}
		}
	});
}


LiteSlider.Flags = {
	AUTOPLAY           : 1,
	AUTOPLAY_ALTERNATE : 2,
	REVERT_ON_END      : 4
}
 